var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("iframe", {
      ref: "checkoutFrame",
      staticStyle: {
        position: "fixed",
        overflow: "hidden",
        "z-index": "999999999",
        width: "100%",
        height: "100%",
        transition: "opacity 0.3s ease 0s",
        margin: "0",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
      },
      attrs: {
        src: _vm.paymentLink,
        frameborder: "0",
        allowfullscreen: "",
        "allow-same-origin": "",
      },
      on: { load: _vm.handleLoad },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }