<template>
  <div>
    <iframe
      @load="handleLoad"
      ref="checkoutFrame"
      :src="paymentLink"
      frameborder="0"
      style="
        position: fixed;
        overflow: hidden;
        z-index: 999999999;
        width: 100%;
        height: 100%;
        transition: opacity 0.3s ease 0s;
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      "
      allowfullscreen
      allow-same-origin
    ></iframe>
  </div>
</template>
<script>
  import VueCookies from "vue-cookies";
  export default {
    props: {
      paymentLink: {
        type: String,
        default: "",
        required: true,
      },
    },
    mounted() {
      window.addEventListener("message", this.handleEvents, false);
      //   const token = VueCookies.get("token");
      //   console.log(this.$refs.checkoutFrame.contentWindow);
      //   if (token) {
      //     this.$refs.checkoutFrame.contentWindow.postMessage(token);
      //     console.log(this.$refs.checkoutFrame.contentWindow);
      //     console.log(token);
      //   }
    },
    methods: {
      handleEvents(event) {
        switch (event.data.type) {
          case "zilla.widget.closed":
            this.$emit("close");
            window.removeEventListener("message", this.handleEvents, false);
            break;
          case "zilla.completed_payment":
            this.$emit("success", event.data.data);
            break;
          case "zilla.event":
            console.log("EVENT", event.data.data);
            break;
          case "Zilla.error":
            console.log("ERROR", event.data.data);
        }
      },
      handleLoad() {
        const token = VueCookies.get("token");
        if (token) {
          this.$refs.checkoutFrame.contentWindow.postMessage(
            JSON.stringify({
              type: "auth",
              data: token,
            }),
            "*"
          );
         
        }
      },
    },
  };
</script>
